.site-header {
  background-color: $grey-blue;

  .container {
    padding: 0;
  }
}

.site-header__top {
  display: none;
  margin-bottom: 28px;

  .search-form input {
    width: 200px;
  }

  .button--cta {
    background-color: $navy;
    color: $white;
    margin: 0 0 0 16px;

    &:hover {
      background-color: $navy-dark;
    }
  }
}

.site-header__bottom {
  display: flex;
  flex-direction: column;
  position: relative;
}

.logo-and-hamburger {
  width: 100%;
  padding: 32px $container__gutter--mobile 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.mobile-header-actions {
  display: flex;
  align-items: flex-end;

  svg {
    display: block;
    color: $navy;
    height: 17px;
    width: auto;
  }
}

.mobile-nav-button {
  background-color: transparent;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  margin-left: 24px;

  > svg:last-child {
    margin: 0;
  }

  .menu--close {
    display: none;
  }

  &[aria-expanded="true"] {
    .menu--open {
      display: none;
    }

    .menu--close {
      display: block;
    }
  }
}

.site-logo {
  display: inline-block;

  img {
    display: block;
    width: auto;
    height: 30px;
  }
}

.main-nav {
  display: none; // Expose with js
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: $grey-blue;
  padding: 1.2rem 0 3.6rem;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  li {
    display: flex;
    flex-direction: column;
  }

  a {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-grow: 1;
    padding: .8rem $container__gutter--mobile;
    text-decoration: none;
    color: $navy;
    font-weight: 600;
    cursor: pointer;
    font-size: 1.8rem;
    line-height: 2.6rem;

    svg {
      margin-left: 8px;
      flex-shrink: 0;
      transition: all $transition;
    }

    &[aria-expanded="true"] svg {
      transform: rotate(180deg);
    }
  }

  // != level1
  ul ul {
    // display: none;
    padding: 1rem 0;

    a {
      font-weight: 400;
    }
  }
}

.level-2-nav-wrapper {
  display: none;
}

// Link member nav
.link-member-header {
  background-color: $navy-dark;
  color: $white;
  padding: 1rem 0;

  a {
    color: inherit;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }

  li {
    margin-left: 1.2rem;
  }
}

@media (min-width: 900px) {
  .site-header {
    padding: 0 0 36px;
    position: relative;

    .container {
      padding-left: $container__gutter--desktop;
      padding-right: $container__gutter--desktop;
    }
  }

  .site-header__top {
    padding-top: 40px;
    display: flex;
    justify-content: flex-end;
  }

  .site-header__bottom {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    position: initial;
  }

  .site-logo img {
    height: 40px;
  }

  .logo-and-hamburger {
    padding: 0;
    width: auto;
    flex-shrink: 0;
  }

  .mobile-header-actions {
    display: none;
  }

  .main-nav {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: initial;
    top: initial;
    width: auto;
    padding: 0;

    // Level 1
    > ul {
      flex-direction: row;
      align-items: flex-end;

      > li {
        &:not(:first-child) {
          margin-left: 32px;
        }

        > a {
          padding: 0;
          justify-content: flex-start;
        }
      }
    }

    // != level1
    ul ul {
      padding: 0;

      a {
        padding: .8rem 0;
      }
    }
  }

  .level-2-nav-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 0 0 72px;
    background-color: $grey-blue;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.2);
    z-index: 10;

    .container {
      display: flex;
      justify-content: flex-end;
    }
  }
}
